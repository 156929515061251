@use "../settings" as s;
@use "../tools" as t;

.s-errors {

    padding-top: 80px;
    padding-bottom: 100px;

    &__title {
        margin-bottom: 40px;
        @include t.ob;
        font-size: 60px;
        line-height: 75px;
        text-align: center;
        color: s.$mine-shaft;
    }
    &__description {
        margin-bottom: 55px;
        @include t.ob;
        font-size: 50px;
        line-height: 63px;
        text-align: center;
        color: s.$mine-shaft;
    }

    &__image {
        text-align: center;
    }

}