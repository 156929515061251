@use "../settings" as s;
@use "../tools" as t;

.c-button {
    button, a {
        margin: 0;
        border: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        height: 60px;
        width: 270px;
        background-color: s.$forest-green;
        @include t.ob;
        font-size: 20px;
        line-height: 25px;
        text-decoration: none;
        color: s.$white;
        transition: background-color .5s, border-color .5s;
        &:hover {
            background-color: s.$camarone;
        }
    }

    &--center {
        button, a {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &--inverse {
        button, a {
            background-color: s.$white;
            border: 3px solid transparent;
            color: s.$camarone;
            &:hover {
                background-color: transparent;
                border: 3px solid s.$white;
                color: s.$white;
            }
        }
    }

    @media(max-width: s.$md - 1){
        button, a {
            width: 100%;
            height: 50px;
        }
    }

}
