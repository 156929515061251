@use "../settings" as s;
@use "../tools" as t;

.s-block {

    margin-bottom: 100px;

    &__image {
        img {
            border-radius: 20px;
            width: 100%;
            aspect-ratio: 682 / 602;
        }
    }

    &__title {
        margin-bottom: 55px;
        @include t.title_30($color: s.$forest-green);
    }

    &__content {
        margin-bottom: 55px;
        @include t.passage;
    }

    &__row {
        &--reverse {
            flex-direction: row-reverse !important;
        }
    }

    @media(max-width: s.$xl - 1){

        margin-bottom: 50px;

        &__row {
            flex-direction: column !important;
            &--reverse {
                flex-direction: column !important;
            }
        }

        &__image {
            margin-bottom: 20px;
        }

        &__title {
            margin-bottom: 24px;
        }
        
        &__content {
            margin-bottom: 24px;
        }
    }
}
