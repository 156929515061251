@use "../settings" as s;
@use "../tools" as t;

.s-cta {

    &__box {
        display: flex;
        align-items: center;
        margin-bottom: 100px;
        border-radius: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 45px;
    }

    &__title {
        margin-right: 45px;
        @include t.ob;
        font-size: 30px;
        line-height: 38px;
        color: s.$white;
    }

    &__button {
        margin-left: auto;
    }

    @media(max-width: s.$lg - 1){
        &__box {
            flex-direction: column;
            margin-bottom: 50px;
            margin-left: -20px;
            width: calc(100% + 40px);
            padding: 35px 20px;
        }
        &__title {
            margin-bottom: 24px;
            margin-right: 0;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
        }
    
        &__button {
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media(max-width: s.$md - 1){
        &__button {
            width: 100%;
        }
    }

}