@use "../settings" as s;
@use "../tools" as t;

.s-services {

    &__title {
        margin-bottom: 55px;
        @include t.title_50;
        text-align: center;
    }

    &__grid {
        margin-bottom: 50px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 35px;
        grid-row-gap: 35px;
        padding-bottom: 6px; 
    }

    @media(max-width: s.$xxl - 1){
        &__grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media(max-width: s.$lg - 1){
        &__title {
            margin-bottom: 25px;
        }
        &__grid {
            margin-bottom: 25px;
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 25px;
        }
    }

}