@use "../settings" as s;
@use "../tools" as t;

.s-accordion {

    $self: &;

    margin-bottom: 100px;

    &__image {
        img {
            border-radius: 20px;
            width: 100%;
            aspect-ratio: 682 / 602;
        }
    }

    &__row {
        &--reverse {
            flex-direction: row-reverse !important;
        }
    }

    @media(max-width: s.$lg - 1){

        margin-bottom: 20px;

        &__image {
            margin-bottom: 30px;
        }
        &__row {
            flex-direction: column !important;
            &--reverse {
                flex-direction: column !important;
            }
        }
    }

}
