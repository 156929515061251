@use "../settings" as s;
@use "../tools" as t;

.s-rbanner {
    margin-bottom: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 10px solid s.$forest-green;
    height: 410px;

    &__title {
        @include t.ob;
        font-size: 60px;
        line-height: 75px;
        color: s.$white;
    }

    &__form {
        width: 576px;
    }

    @media(max-width: s.$lg - 1){
        margin-bottom: 50px;
        height: 220px;

        &__title {
            margin-bottom: 30px;
            font-size: 27px;
            line-height: 35px;
            text-align: center;
        }

        &__form {
            width: 100%;
        }    

    }

}
