@use "../settings" as s;
@use "../tools" as t;

.s-scontact {

    margin-bottom: 100px;

    &__box {
        border-radius: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 40px;
    }

    &__title {
        margin-bottom: 40px;
        @include t.ob;
        font-size: 30px;
        line-height: 40px;
        color: s.$white;
    }

    &__content {
        @include t.passage;
        color: s.$white;
    }

    &__image {
        img {
            border-radius: 20px;
            width: 100%;
            aspect-ratio: 642 / 624;
        }
    }

    @media(max-width: s.$lg - 1){

        margin-bottom: 50px;

        &__box {
            padding: 20px 20px 0px;
        }
        &__title {
            margin-bottom: 25px;
            font-size: 18px;
            line-height: 24px;
        }
        &__content {
            margin-bottom: 24px;
        }
        &__image {
            margin-bottom: 30px;
        }
    }

    @media(max-width: s.$sm - 1){
        &__box {
            margin-left: -20px;
            width: calc(100% + 40px);
        }
    }

}