@use "../settings" as s;
@use "../tools" as t;

.c-dropdown {

    $self: &;

    position: relative;

    &__drop {
        margin: 0;
        border: 0;
        box-shadow: none;
        background: transparent;
        padding: 0;
        @include t.osb;
        font-size: 20px;
        line-height: 25px;
        color: s.$mine-shaft;
    }

    &__nodrop {
        margin: 0;
        border: 0;
        box-shadow: none;
        background: transparent;
        padding: 0;
        @include t.osb;
        font-size: 20px;
        line-height: 25px;
        text-decoration: none;
        color: s.$mine-shaft;

        &:hover {
            text-decoration: none;
            color: s.$mine-shaft;
        }
    }

    &__button {
        margin: 0;
        border: 0;
        border-radius: 15px;
        box-shadow: none;
        background-color: s.$forest-green;
        padding: 17px 48px;
        @include t.osb;
        font-size: 20px;
        line-height: 25px;
        text-decoration: none;
        color: s.$white; 
        &:hover {
            color: s.$white; 
        }
    }

    &__list {
        position: absolute;
        left: -30px;
        display: none;
        margin-top: 56px;
        @include t.box_shadow;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        background-color: s.$white;
        padding: 30px;
        ul {
            display: block;
            li {
                display: block;
                margin-left: 0;
                margin-bottom: 24px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }

    &__link {
        @include t.om;
        font-size: 20px;
        line-height: 20px;
        text-decoration: none;
        color: s.$mine-shaft;
        &:hover {
            color: s.$mine-shaft;
        }

        &--main {
            color: s.$forest-green;
            &:hover {
                color: s.$forest-green;
            }
        }

    }

    &--show {
        #{ $self }__list {
            display: block;
        }
    }


    @media(max-width: s.$xxl - 1){
        &__button {
            padding-left: 17px;
            padding-right: 17px;
        }
    }

    @media(max-width: s.$lg - 1){

        margin-bottom: 16px;
        width: 100%;

        &__button {
            margin: 0;
            box-shadow: none;
            border: 0;
            border-radius: 0;
            background-color: transparent;
            padding: 0;
            @include t.ob;
            font-size: 20px;
            line-height: 20px;
            text-decoration: none;
            color: s.$mine-shaft;
            &:hover {
                color: s.$mine-shaft;
            }
        }

        &__link {
            @include t.om;
            &--main {
                @include t.ob;
            }
        }

        &__drop {
            display: flex;
            justify-content: space-between;
            width: 100%;
            @include t.ob;
        }

        &__nodrop {
            display: block;
            margin-bottom: 17px;
        }

        &__list {
            position: static;
            margin-top: 0;
            box-shadow: none;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            padding: 20px 0 15px;
        }

    }
}