@use "../settings" as s;
@use "../tools" as t;

.c-language {
    
    &__image {
        margin-bottom: 20px;
        img {
            width: 100%;
            @include t.box_shadow;
        }
    }

    &__title {
        margin-bottom: 20px;
        @include t.title_25;
        text-align: center;
    }
   
}