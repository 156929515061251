@font-face {
  font-family: "Outfit";
  font-weight: 300;
  src: url('/static/fonts/googlefonts/Outfit-Light.ttf');
}

@font-face {
  font-family: "Outfit";
  font-weight: 400;
  src: url('/static/fonts/googlefonts/Outfit-Regular.ttf');
}

@font-face {
  font-family: "Outfit";
  font-weight: 500;
  src: url('/static/fonts/googlefonts/Outfit-Medium.ttf');
}

@font-face {
  font-family: "Outfit";
  font-weight: 600;
  src: url('/static/fonts/googlefonts/Outfit-SemiBold.ttf');
}

@font-face {
  font-family: "Outfit";
  font-weight: 700;
  src: url('/static/fonts/googlefonts/Outfit-Bold.ttf');
}