@use "../settings" as s;
@use "../tools" as t;

.s-banner {

    display: flex;
    align-items: center;

    margin-bottom: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 10px solid s.$forest-green;
    min-height: 410px;

    &__container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &__title {
        margin-bottom: 35px;
        @include t.ob;
        font-size: 60px;
        line-height: 75px;
        color: s.$white;
    }

    &__content {
        @include t.om;
        font-size: 24px;
        line-height: 32px;
        color: s.$white;

        p {
            margin-bottom: 32px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    @media(max-width: s.$md - 1){
        margin-bottom: 50px;
        min-height: 316px;

        &__title {
            margin-bottom: 30px;
            font-size: 27px;
            line-height: 35px;
            text-align: center;
        }
        
        &__content {
            font-size: 18px;
            line-height: 26px;
            text-align: center;
        }
    }
}
