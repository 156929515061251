@use "../settings" as s;
@use "../tools" as t;

ul.errorlist {
    display: block;
    margin-bottom: 10px;
    padding-left: 0;
    li {
        display: block;
        @include t.passage;
        text-align: right;
        color: s.$white;
    }
}