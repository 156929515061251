// Colours

$camarone: #135500;
$white: #FFFFFF;
$mine-shaft: #232323;
$forest-green: #3F822C;
$dove-gray: #707070;
$tenn: #CB6500;
$gray: #888888;
$scorpion: #575757;
$deep-fir: #0D3C00;
$alto: #CECECE;
$shark: #26292E;
$alabaster_1: #FCFCFC;
$alabaster_2: #F8F8F8;
