@use "../settings" as s;
@use "../tools" as t;

@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~owl.carousel/dist/assets/owl.theme.default.css';

.owl-theme .owl-dots .owl-dot span {
    background: rgba(s.$forest-green, 25%);
    border-radius: 7px;
    width: 14px;
    height: 14px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: s.$forest-green;
}

.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 50px;
}

@media(max-width: s.$md - 1){
    .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 24px;
    }  
}