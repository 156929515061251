@use "../settings" as s;
@use "../tools" as t;

.c-location {

    $self: &;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    border-radius: 20px;
    @include t.box_shadow;
    background-color: s.$alabaster_1;

    &__image {
        img {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            width: 100%;
            aspect-ratio: 466 / 301;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding: 30px 30px 45px;
        height: 100%;
    }

    &__bottom {
        margin-top: auto;
    }

    &__title {
        margin-bottom: 30px;
        @include t.title_25;
    }

    &__methods {
        margin-bottom: 30px;
    }

    &__method {
        margin-bottom: 20px;
        a {
            @include t.ob;
            font-size: 18px;
            line-height: 22px;
            text-decoration: none;
            color: s.$forest-green;
            i {
                margin-right: 15px;
            }
            &:hover {
                color: s.$forest-green;
            }
        }
    }

    &__address {
        margin-bottom: 30px;
        @include t.passage;
    }

    &__parking {
        margin-bottom: 30px;
        @include t.ol;
        font-size: 18px;
        line-height: 28px;
        color: s.$scorpion;

        p {
            margin-bottom: 28px;
            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    @media(max-width: s.$xxl - 1){
        &--odd {
            grid-column: span 2;
        }
    }

    @media(max-width: s.$md - 1){
        &--odd {
            grid-column: span 1;
        }

        &__info {
            padding: 20px 10px 20px;
        }
    }

}