@use "../settings" as s;
@use "../tools" as t;

.s-team {

    &__title {
        margin-bottom: 55px;
        @include t.ob;
        font-size: 30px;
        line-height: 38px;
        color: s.$mine-shaft;
    }

    &__grid {
        margin-bottom: 70px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 35px;
        grid-row-gap: 35px;
        padding-bottom: 6px; 
    }

    @media(max-width: s.$xxl - 1){
        &__grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media(max-width: s.$lg - 1){
        &__title {
            margin-bottom: 20px;
            font-size: 22px;
            line-height: 32px;
            text-align: center;
        }
        
        &__grid {
            margin-bottom: 30px;
            grid-template-columns: repeat(1, 1fr);
        }
    }
}