// Tools – Globally used mixins.
@use "../settings" as s;

@mixin ol {
  font-family: "Outfit";
  font-weight: 300;
}

@mixin or {
  font-family: "Outfit";
  font-weight: 400;
}

@mixin om {
  font-family: "Outfit";
  font-weight: 500;
}

@mixin osb {
  font-family: "Outfit";
  font-weight: 600;
}

@mixin ob {
  font-family: "Outfit";
  font-weight: 700;
}

@mixin box_shadow {
  box-shadow: 2px 3px 6px 0px rgba(0,0,0,0.16);
}

@mixin title_30($color: s.$mine-shaft) {
  @include ob;
  font-size: 30px;
  line-height: 38px;
  color: $color;
}

@mixin title_50($color: s.$mine-shaft) {
  @include ob;
  font-size: 50px;
  line-height: 63px;
  color: $color;

  @media(max-width: s.$md - 1){
    font-size: 22px;
    line-height: 32px;
  }

}

@mixin title_25($color: s.$mine-shaft) {
  @include ob;
  font-size: 25px;
  line-height: 38px;
  color: $color;

  @media(max-width: s.$lg){
    font-size: 18px;
    line-height: 24px;
  }

}

@mixin passage($style: 'default') {
  @include or;
  font-size: 18px;
  line-height: 32px;
  color: s.$mine-shaft;

  @if($style == 'default'){

    h1 {
      margin-top: 55px;
      margin-bottom: 40px;
      @include ob;
      font-size: 60px;
      line-height: 75px;
      color: s.$mine-shaft;
    }

    h2 {
      margin-top: 50px;
      margin-bottom: 63px;
      @include ob;
      font-size: 50px;
      line-height: 63px;
      color: s.$mine-shaft;
    }

    h3 {
      margin-top: 25px;
      margin-bottom: 38px;
      @include ob;
      font-size: 25px;
      line-height: 38px;
      color: s.$mine-shaft;
    }
  }

  @if($style == 'cost-page'){

    h1 {
      margin-top: 55px;
      margin-bottom: 40px;
      @include ob;
      font-size: 50px;
      line-height: 63px;
      color: s.$mine-shaft;
    }

    h2 {
      margin-top: 55px;
      margin-bottom: 40px;
      @include ob;
      font-size: 30px;
      line-height: 38px;
      color: s.$mine-shaft;
    }

    h3 {
      margin-top: 55px;
      margin-bottom: 40px;
      @include ob;
      font-size: 25px;
      line-height: 38px;
      color: s.$mine-shaft;
    }
  }
  
  p {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: s.$forest-green;
    &:hover {
      color: s.$forest-green;
    }
  }

  @media(max-width: s.$lg - 1){
    font-size: 16px;
    line-height: 22px;
    p {
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 27px;
      line-height: 37px;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 22px;
      line-height: 32px;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 21px;
    }

  }

}