@use "../settings" as s;
@use "../tools" as t;

.c-testimony {

    position: relative;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 20px;
    @include t.box_shadow;
    padding: 30px 30px 45px;

    background-position: top 5px left 5px, bottom 5px right 5px;
    background-repeat: no-repeat, no-repeat;
    background-image: url('/static/img/sixtysix.png'), url('/static/img/nintynine.png');
    background-size: 33% auto, 33% auto;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('/static/img/sixtysix@2x.png'), url('/static/img/nintynine@2x.png');
    }

    &__title {
        margin-bottom: 30px;
        @include t.title_25;
    }
    &__underline {
        margin-bottom: 30px;
        width: 70px;
        height: 5px;
        background-color: s.$forest-green;
    }

    &__content {
        margin-bottom: 30px;
        @include t.passage;
    }

    &__author {
        @include t.ob;
        font-size: 18px;
        line-height: 22px;
        color: s.$forest-green;
    }

    @media(max-width: s.$lg - 1){
        padding: 23px 12px 36px;

        &__title {
            margin-bottom: 20px;
        }
        &__underline {
            margin-bottom: 20px;
        }
        &__content {
            margin-bottom: 24px;
        }
        &__author {
            font-size: 16px;
            line-height: 22px;
        }
    }

}
