@use "../settings" as s;
@use "../tools" as t;

.s-page {
    margin-top: 186px;

    @media(max-width: s.$lg - 1){
        margin-top: 124px;
    }

}