@use "../settings" as s;
@use "../tools" as t;

.c-tab {

    $self: &;

    margin-bottom: 40px;

    &__title {
        position: relative;
        margin: 0;
        padding: 0 52px 0 0;
        border: 0;
        box-shadow: none;
        background-color: transparent;
        width: 100%;
        @include t.ob;
        font-size: 30px;
        line-height: 38px;
        text-align: left;
        color: s.$mine-shaft;

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            content: "\+";
        }

    }

    &__content {
        display: none;
        margin-top: 30px;
        @include t.passage($style: 'cost-page');
        color: s.$mine-shaft;
    }

    &--seperators {
        border-bottom: 1px solid s.$alto;
        padding-bottom: 40px;
    }

    &--show {
        #{ $self }__title {
            &:after {
                content: "\f068";
            }
        }
        #{ $self }__content {
            display: block;
        }
    }


    @media(max-width: s.$lg - 1){

        margin-bottom: 24px;

        &__title {
            padding: 0 48px 0 0;
            font-size: 18px;
            line-height: 24px;
        }

        &__content {
            margin-top: 24px;
        }

        &--seperators {
            padding-bottom: 24px;
        }

    }

}