h1, h2, h3, h4, h5, h6 {
  &.inherit {
    margin: 0 !important;
    padding: 0 !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
    color: inherit !important;
  }
}