@use "../settings" as s;
@use "../tools" as t;

.s-credit {
    padding-bottom: 30px;
    text-align: center;

    &__logo {
        display: inline-block;
        width: 164px !important;
        max-width: 164px !important;
        img {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }


    @media(max-width: s.$lg - 1){
        &__logo {
            width: 135px !important;
            max-width: 135px !important;
        } 
    }

}