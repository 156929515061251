@use "../settings" as s;
@use "../tools" as t;

.c-member {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    border-radius: 20px;
    @include t.box_shadow;
    background-color: s.$alabaster_1;

    &__image {
        img {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            width: 100%;
            aspect-ratio: 1 / 1;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding: 30px 30px 45px;
        height: 100%;
    }

    &__name {
        margin-bottom: 15px;
        @include t.title_25;
    }

    &__role {
        margin-bottom: 30px;
        @include t.title_25($color: s.$forest-green);
    }

    &__methods {
        margin-bottom: 0;
    }

    &__method {
        margin-bottom: 20px;
        a {
            @include t.ob;
            font-size: 18px;
            line-height: 22px;
            text-decoration: none;
            color: s.$forest-green;
            i {
                margin-right: 15px;
            }
            &:hover {
                color: s.$forest-green;
            }
        }
        &--mine-shaft {
            a {
                color: s.$mine-shaft;
                &:hover {
                    color: s.$mine-shaft;
                }
            }
        }
        &--mb-0 {
            margin-bottom: 0;
        }
    }

    &__description {
        margin-bottom: 20px;

        @include t.passage;
        color: s.$mine-shaft;

        a {
            color: s.$forest-green;
            &:hover {
                color: s.$forest-green;
            }
        }

        &--mb-0 {
            margin-bottom: 0;
        }
    }

    @media(max-width: s.$md - 1){
        &__info {
            padding: 20px 15px 30px;
        }
    }

}