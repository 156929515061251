@use "../settings" as s;
@use "../tools" as t;

.s-header {

    $self: &;

    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;

    @include t.box_shadow;

    &__runner {
        background-color: s.$camarone;
        padding-top: 9px;
        padding-bottom: 9px;
        @include t.osb;
        font-size: 25px;
        line-height: 32px;
        color: s.$white;

        a {
            text-decoration: none;
            color: s.$white;
            &:hover {
                color: s.$white;
            }
        }

        i {
            margin-right: 10px;
        }

    }

    &__bar {
        background-color: s.$white;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__telephone {
        margin-left: auto;
    }

    &__menu {
        display: flex;
        align-items: center;

        ul {
            margin: 0;
            white-space: nowrap;
            li {
                display: inline-block;
                margin-left: 50px;
            }
        }
    }

    &__logo {
        margin-right: auto;
    }

    &__foot {
        //position: fixed;
        //bottom: 0;
        //left: 0;
        //right: 0;
        margin-top: auto;
        display: none;
        height: 112px;
        background-color: s.$forest-green;
        padding: 25px 20px;
    }

    &__foot-detail {
        display: block;
        margin-bottom: 17px;
        @include t.om;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        color: s.$white;
        &:hover {
            color: s.$white;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__burger {
        display: none;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        border: 0;
        background: transparent;
        padding: 0;
        width: 33px;
        height: 18px;
    }
  
    &__burger-bar {
        background-color: s.$forest-green;
        width: 33px;
        height: 3px;
    }

    &__close {
        position: relative;
        display: none;
        margin: 0;
        border: 0;
        background: transparent;
        width: 26px;
        height: 26px;
        padding: 0;
        transform: rotate(45deg);
    }

    &__criss {
        position: absolute;
        display: block;
        top: 11px;
        left: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: s.$mine-shaft;
    }

    &__cross {
        position: absolute;
        display: block;
        left: 11px;
        top: 0;
        bottom: 0;
        width: 3px;
        height: 100%;
        background-color: s.$mine-shaft;
    }

    @media(max-width: s.$xxxl - 1){
        &__opening {
            display: none;
        }
        &__telephone {
            margin-right: auto;
        }
    }

    @media(max-width: s.$xxl - 1){
        .s-header__menu {
            ul {
                li {
                    margin-left: 10px;
                }
            }
        }
    }

    @media(max-width: s.$lg - 1){

        &__flex {
            position: relative;
            left: -10px;
            width: calc(100% + 20px);
        }

        &__telephone {
            font-size: 13px;
            line-height: 16px;
        }

        &__logo {
            img {
                height: 52px;
            }
        }

        &__runner {
            i {
                margin-right: 4px;
            }
        }

        &__menu {
            position: fixed;
            display: none;
            top: 124px;
            bottom: 112px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: s.$white;
            align-items: start;
            overflow-y: scroll;

            ul {
                width: 100%;
                li {
                    display: block;
                    margin-left: 0;
                }
            }

        }

        &__info {
            padding: 20px;
        }

        &__burger {
            display: flex;
        }

        &__foot {
            display: block;
        }

        &--show {
            #{ $self }__menu {
                display: flex;
                flex-direction: column;
                align-items: stretch;
            }
            #{ $self }__burger {
                display: none;
            }
            #{ $self }__close {
                display: block;
            }
        }

    }

}
