@use "../settings" as s;
@use "../tools" as t;

.s-costs {

    &__title {
        margin-bottom: 55px;
        @include t.title_50;
    }

    &__lead {
        margin-bottom: 100px;
        @include t.passage;
    }

    &__accordion-title {
        margin-bottom: 55px;
        @include t.title_50;
        text-align: center;
    }

    &__tabs {
        margin-bottom: 100px;
    }

    &__body {
        margin-bottom: 100px;
        @include t.passage($style: 'cost-page');
    }

    @media(max-width: s.$lg - 1){
        &__title {
            margin-bottom: 24px;
        }
        &__lead {
            margin-bottom: 50px;
        }
        &__tabs {
            margin-bottom: 50px;
        }
        &__body {
            margin-bottom: 50px;
        }
        &__accordion-title {
            text-align: left;
        }
    }

}