@use "../settings" as s;
@use "../tools" as t;

.c-service {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    border-radius: 20px;
    @include t.box_shadow;
    background-color: s.$alabaster_1;

    &__image {
        img {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            width: 100%;
            aspect-ratio: 466 / 301;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding: 30px 30px 45px;
        height: 100%;
    }

    &__bottom {
        margin-top: auto;
    }

    &__title {
        margin-bottom: 30px;
        @include t.title_25;
    }

    &__lead {
        margin-bottom: 30px;
        @include t.passage; 
    }

    @media(max-width: s.$md - 1){
        &__info {
            padding: 20px 10px 20px;
        }
    }

}