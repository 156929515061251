@use "../settings" as s;
@use "../tools" as t;

.s-testimony {
    padding-bottom: 100px;

    &__title {
        margin-bottom: 50px;
        @include t.title_50;
        text-align: center;
    }

    @media(max-width: s.$lg - 1){
        padding-bottom: 50px;

        &__title {
            margin-bottom: 24px;
            padding-left: 5%;
            padding-right: 5%;
        }
    }

}