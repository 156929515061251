@use "../settings" as s;
@use "../tools" as t;

.c-form {

    &__group {
        margin-bottom: 30px;
        &--button {
            text-align: right;
        }
    }

    &__label {
        margin-bottom: 14px;
        @include t.om;
        font-size: 18px;
        line-height:23px;
        color: s.$white;
    }

    &__input {
        border: 0;
        border-radius: 15px;
        height: 60px;
        width: 100%;
        padding-left: 18px;
        padding-right: 18px;
        background-color: s.$white;
        @include t.or;
        font-size: 18px;
        color: s.$mine-shaft;

        &::placeholder {
            opacity: 1;
            color: s.$gray;
        }
    }

    &__textarea {
        border: 0;
        border-radius: 15px;
        height: 110px;
        width: 100%;
        padding: 15px 18px;
        background-color: s.$white;
        @include t.or;
        font-size: 18px;
        color: s.$mine-shaft;

        &::placeholder {
            opacity: 1;
            color: s.$gray;
        }
    }

    &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        border: 3px solid s.$tenn;
        background-color: s.$tenn;
        width: 270px;
        height: 60px;
        @include t.ob;
        font-size: 20px;
        line-height: 25px;
        color: s.$white;

        transition: background-color .5s, border-color .5s;
        &:hover {
            border: 3px solid s.$tenn;
            color: s.$white;
        }
    }

    &__captcha {
        @include t.ob;
        font-size: 20px;
        line-height: 25px;
        text-align: right;
        color: s.$white;
    }

    &__raise {
        position: relative;
        visibility: hidden;
        top: -190px;
    }

    @media(max-width: s.$lg - 1){
        &__input {
            height: 50px;
        }
    }

    @media(max-width: s.$md - 1){
        &__button {
            width: 100%;
            height: 50px;
        }
    }

}