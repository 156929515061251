@use "../settings" as s;
@use "../tools" as t;

.c-search {

    margin-bottom: 70px;

    &__group {
        height: 60px;
        border-radius: 20px !important;
        background-color: s.$white;
    }

    &__input {
        border-bottom-left-radius: 20px !important;
        border-top-left-radius: 20px !important;
        border: 0;
        padding-left: 18px;
        padding-right: 18px;
        background-color: s.$white;
        @include t.or;
        font-size: 18px;
        line-height: 46px;
        color: s.$mine-shaft;
        
        &::placeholder {
            opacity: 1;
            color: s.$gray;
        }
        &:focus {
            box-shadow: none;
        }
    }

    &__button {
        border-radius: 20px !important;
        border: 0;
        background-color: s.$tenn;
        width: 60px;
        height: 60px;
        font-size: 20px;
        line-height: 20px;
        color: s.$white;
        &:hover {
            background-color: s.$tenn;
            color: s.$white;
        }
    }

    @media(max-width: s.$lg - 1){
        &__group {
            height: 50px;
        }
        &__button {
            height: 50px;
        }
        &__input {
            line-height: 36px;
        }
    }

}