@use "../settings" as s;
@use "../tools" as t;

.c-table {
    background-color: green($color: #000000);
    &__scroll {
        display: none;
        margin-bottom: 24px;
        @include t.ob;
        font-size: 16px;
        line-height: 32px;
        color: s.$forest-green;
    }

    th {
        border: 1px solid lightgrey;
        @include t.ob;
        font-size: 30px;
        line-height: 38px;
        color: s.$mine-shaft;
    }

    td {
        @include t.or;
        font-size: 25px;
        line-height: 32px;
        color: s.$mine-shaft;     
        border: 1px solid lightgrey !important;
    }

    th, td {
        
        height: 70px;
        background-color: s.$alabaster_1;
        padding-left: 20px;
        padding-right: 20px;
        white-space: nowrap;
        vertical-align: middle;
    }

    th:nth-child(2n+1), td:nth-child(2n+1){
        background-color: s.$alabaster_2;
    }

    @media(max-width: s.$lg - 1){
    
        &__scroll {
            display: block;
        }

        th {
            font-size: 16px;
            line-height: 24px;
        }
    
        td {
            font-size: 16px;
            line-height: 24px;
      
        }

        th, td {
            height: 50px;
            background-color: s.$alabaster_1;
            padding-right: 20px;
            padding-left: 20px;
        }

    }

}
