@use "../settings" as s;
@use "../tools" as t;

.s-hbanner {
    margin-bottom: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 10px solid s.$forest-green;
    min-height: 640px;

    &__container {
        min-height: 640px;
    }

    &__logo {
        margin-bottom: 50px;
        padding-top: 66px;
        text-align: center;
    }

    &__title {
        margin-bottom: 60px;
        padding-left: 2%;
        padding-right: 2%;
        @include t.ob;
        font-size: 60px;
        line-height: 75px;
        text-align: center;
        color: s.$white;
    }

    &__search {
        margin-left: auto;
        margin-right: auto;
        width: 578px;
    }

    @media(max-width: s.$xl - 1){
        &__logo {
            margin-bottom: 30px;
        }
        &__title {
            margin-bottom: 40px;
        }
    }

    @media(max-width: s.$lg - 1){
        min-height: 413px;

        &__container {
            min-height: 413px;
        }

        &__logo {
            margin-bottom: 35px;
            padding-top: 30px;
            img {
                height: 78px;
            }
        }

        &__title {
            margin-bottom: 35px;
            padding-left: 5%;
            padding-right: 5%;
            font-size: 27px;
            line-height: 35px;
        }


        &__search {
            width: 100%;
        }

    }

}
