@use "../settings" as s;
@use "../tools" as t;

.s-language {
    padding-bottom: 80px;

    &__title {
        margin-bottom: 50px;
        @include t.title_50;
        text-align: center;
    }

    &__content {
        margin-bottom: 75px;
        @include t.passage;
        text-align: center;
    }

    @media(max-width: s.$lg - 1){

        padding-bottom: 30px;

        &__title {
            margin-bottom: 24px;
            font-size: 22px;
            line-height: 32px;
        }
        &__content {
            margin-bottom: 25px;
        }

    }

}