@use "../settings" as s;
@use "../tools" as t;

.s-hours {
    margin-bottom: 100px;

    &__box {
        border-radius: 20px;
        @include t.box_shadow;
        overflow: hidden;
    }

    &__img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__info {
        padding: 20px 30px 35px;
    }

    &__title {
        margin-bottom: 30px;
        @include t.ob;
        font-size: 25px;
        line-height: 28px;
        color: s.$mine-shaft;
    }

    &__content {
        @include t.passage;
    }

    @media(max-width: s.$lg - 1){
        display: none;
    }

}