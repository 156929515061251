@use "../settings" as s;
@use "../tools" as t;

.s-content {
    padding-top: 80px;
    padding-bottom: 100px;

    &__title {
        margin-bottom: 40px;
        @include t.ob;
        font-size: 60px;
        line-height: 75px;
        color: s.$mine-shaft;
    }

    &__content {
        @include t.passage;
    }

    @media(max-width: s.$lg - 1){
        padding-top: 40px;
        padding-bottom: 50px;

        &__title {
            margin-bottom: 30px;
            font-size: 27px;
            line-height: 35px;
        } 
    }

}