@use "../settings" as s;
@use "../tools" as t;

.s-cookie {
    position: fixed;
    z-index: 100;
    display: none;
    left: 75px;
    bottom: 75px;
    @include t.box_shadow;
    border-radius: 20px;
    background-color: s.$white;
    width: 100%;
    max-width: 526px;
    padding: 30px 45px;
    font-size: 16px;
    line-height: 24px;
    color: s.$shark;
    p {
        margin-bottom: 0;
    }
    a {
        text-decoration: underline;
        color: s.$forest-green;
        &:hover {
            text-decoration: underline;
            color: s.$forest-green;     
        }
    }

    &__close {
        position: absolute;
        display: block;
        top: 15px;
        right: 15px;
        margin: 0;
        border: 0;
        width: 15px;
        height: 15px;
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('/static/img/close.png');
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url('/static/img/close@2x.png');
        }

    }

    &--show {
        display: block;
    }

    @media(max-width: s.$lg - 1){
        left: 15px;
        right: 15px;
        bottom: 15px;
        width: auto;
        max-width: 100%;
        padding-top: 45px;
        padding-bottom: 45px;
        text-align: center;
    }

}