@use "../settings" as s;
@use "../tools" as t;

.s-results {
    padding-bottom: 150px;

    &__link {
        text-decoration: none;
    }

    &__search {
        margin-bottom: 100px;
        @include t.ob;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        color: s.$mine-shaft;
    }

    @media(max-width: s.$lg - 1){

        padding-bottom: 50px;

        &__search {
            margin-bottom: 50px;
            font-size: 22px;
            line-height: 28px;
        }
    }

}
