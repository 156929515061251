@use "../settings" as s;
@use "../tools" as t;

.c-result {

    $self: &;

    &__title {
        margin-bottom: 20px;
        @include t.ob;
        font-size: 30px;
        line-height: 38px;
        color: s.$mine-shaft;

        a {
            text-decoration: none;
            color: s.$mine-shaft;
            &:hover {
                text-decoration: none;
                color: s.$mine-shaft;    
            }
        }

    }
    &__breadcrumbs {
        margin-bottom: 20px;
        ul {
            display: block;
            margin-bottom: 0;
            padding-left: 0;
            list-style-type: none;
        }
        li {
            display: inline-block;
            margin-bottom: 0;
            @include t.om;
            font-size: 18px;
            line-height: 32px;
            color: s.$forest-green;
            &:after {
                margin-left: 7px;
                content: '>';
            }
            &:last-child {
                &:after {
                    content: none;
                }
            }
        }
    }

    &__content {
        margin-bottom: 40px;
        padding-bottom: 70px;
        border-bottom: 1px solid s.$alto;
        @include t.passage;
    }

    &:last-child {
        #{ $self }__content {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }     
    }

    @media(max-width: s.$lg - 1){
        &__title {
            margin-bottom: 24px;
            font-size: 22px;
            line-height: 32px;
        }

        &__breadcrumbs {
            margin-bottom: 20px;
            li {
                font-size: 16px;
                line-height: 26px;
                &:after {
                    margin-left: 4px;
                }
            }
        }

        &__content {
            margin-bottom: 24px;
            padding-bottom: 24px;
        }

    }

}
