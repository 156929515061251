@use "../settings" as s;
@use "../tools" as t;

.s-footer {
    background-color: s.$deep-fir;
    padding-top: 95px;
    padding-bottom: 85px;

    &__logo {
        margin-bottom: 40px;
    }

    &__methods {
        margin-bottom: 30px;
    }

    &__address {
        @include t.om;
        font-size: 16px;
        line-height: 22px;
        color: s.$white;

        p {
            margin-bottom: 22px;
            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    &__method {
        margin-bottom: 20px;
        a {
            @include t.om;
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
            color: s.$white;
            i {
                margin-right: 15px;
            }
            &:hover {
                color: s.$white;
            }
        }
    }

    &__list {
        margin-bottom: 30px;
    }

    &__title {
        margin-bottom: 20px;
        @include t.ob;
        font-size: 16px;
        line-height: 22px;

        color: s.$white;
        a {
            text-decoration: none;
            color: s.$white;
            &:hover {
                color: s.$white;
            }
        }
    }

    &__subtitle {
        margin-bottom: 20px;
        @include t.om;
        font-size: 16px;
        line-height: 22px;
        color: s.$white;
        a {
            text-decoration: none;
            color: s.$white;
            &:hover {
                color: s.$white;
            }
        }
    }

    &__networks {
        margin-bottom: 70px;
        font-size: 18px;
        line-height: 22px;
        color: s.$white;
        a {
            margin-right: 20px;
            text-decoration: none;
            color: s.$white;
            &:hover {
                color: s.$white;
            }
        }
    }

    &__regulated {
        
    }

    @media(max-width: s.$md - 1){
        padding-top: 30px;
        padding-bottom: 0px;  

        &__logo {
            img {
                height: 91px;
            }
        }

        &__regulated {
            margin-bottom: 40px;
        }

        &__address {
            margin-bottom: 40px;
        }

        &__methods {
            margin-bottom: 40px;
        }

        &__list {
            margin-bottom: 40px;
        }

    }

}